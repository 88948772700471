import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {environment} from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class CallLogsService {
    public callLogsList: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    constructor(private _httpClient: HttpClient) {}

    //Call Logs List
    //get call Logs

apiUrl = environment.apiUrl;

        getCallLogs(Page: number, Row: number, groupId: string, filter_items: any[]) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

        const httpOptions = {
            headers: headers_object,
        };

        // Prepare the request body
        const requestBody: any = {
            page: Page,
            rows: Row,
            groupId,
            filter_items: filter_items,
        };


        return this._httpClient.post(
            environment.apiUrl+'call/log/list',
            requestBody,
            httpOptions
        );
    }

  getCallLogsDownload(Page: number, Row: number, groupId: string, filter_items: any[]) {
    const token = localStorage.getItem('token');
    const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

    const httpOptions = {
        headers: headers_object,
    };

    // Prepare the request body
    const requestBody: any = {
        page: Page,
        rows: Row,
        groupId,
        filter_items: filter_items,
    };

    return this._httpClient.post(
        environment.apiUrl + 'call/log/list/download',
        requestBody,
        httpOptions
    );
}




    getFilterValue(groupId,filterName,query:any='') {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Add the bearer token here
        });
        return this._httpClient.get(
            `${environment.apiUrl}call/log/filter/list?groupId=${groupId}&filter=${filterName}&query=${query}&page=1&rows=25`,{headers}
        );
    }


    ////transcriptions
    getTranscriptions(calluuid) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
           `${this.apiUrl}call/getTranscriptions`,
            { calluuid: calluuid },
            httpOptions
        );
    }

    ////recordings
    getRecordings(key) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getIndicators',
            { recordingKey: key },
            httpOptions
        );
    }

    reAnalyzed(key, uuid, source) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/reAnalyze',
            { recordingKey: key, calluuid: uuid, sourceNumber: source },
            httpOptions
        );
    }
}
